
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import LoadingMask from "@/components/common/LoadingMask.vue";
import { JFFormControl, JFTextAreaField } from "jfrog-ui-vue-essentials";
import { PaymentType, QuickGetAQuoteRequest } from "@jfrog-ba/myjfrog-common";
import { LoadingMaskProps } from "@/types/loadingMask";
import { PlanModel, QuickPurchaseData } from "@/types/localtypes";
import QuickPurchasePageTitle from "@/components/landingPages/quickPurchase/QuickPurchasePageTitle.vue";

@Component({
  name: "QuickPurchaseGetAQuote",
  $_veeValidate: { validator: "new" },
  components: { QuickPurchasePageTitle, JFFormControl, JFTextAreaField, LoadingMask },
})
export default class QuickPurchaseGetAQuote extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Inject() readonly quickPurchaseBus!: Vue;
  @Getter("quickPurchaseData", { namespace: "quickPurchase" })
  quickPurchaseData!: QuickPurchaseData;
  @Prop() private planName!: string;
  @Prop() private paymentType!: PaymentType;
  @Prop() private core!: PlanModel["core"];
  @Prop() private extraFeatures!: string;
  isLoading: boolean = false;
  comments: string = "";

  get dataGtmPage() {
    return `quick-purchase-contact-us-${this.$utils.toKebabCase(this.planName, true)}-${this.core}`;
  }

  get sendGtmEvent() {
    return `${this.dataGtmPage}|send-button`;
  }

  get backToPricingGtmEvent() {
    return `${this.dataGtmPage}|back-to-pricing-button`;
  }

  get loadingMaskProps(): LoadingMaskProps {
    return { loading: this.isLoading };
  }

  get canSendForm() {
    return !this.isLoading && !this.errors.any();
  }

  toPlans() {
    this.$router.push({ path: "pricing" });
  }

  get devSecOpsPlanSuffix() {
    return this.core === "DEVSECOPS" ? " with Advanced Security Pack" : "";
  }

  get explanationsText() {
    return `Hi,<br/><br/>Thank you for your interest in ${this.planName}${this.devSecOpsPlanSuffix}, one of our representatives will contact you.`;
  }
  get serverName() {
    return this.quickPurchaseData.serverName;
  }

  get isEPlus() {
    return this.paymentType === "ENTERPRISE_PLUS";
  }
  async sendForm() {
    if (!(await this.$validator.validateAll())) {
      return;
    }
    try {
      this.isLoading = true;
      const request: QuickGetAQuoteRequest = {
        marketoCookie: this.$jfMarketo.getCookie(),
        actionType: "upgrade",
        serverName: this.serverName,
        comments: this.comments + "|" + this.extraFeatures,
        reason: "get_a_quote",
        isEnterprisePlus: this.isEPlus.toString(),
        core: this.core,
        token: this.quickPurchaseData.token,
      };
      await this.$jfSubscriptions.sendQuickGetAQuote(request);
      this.$jfNotification.success({
        text: this.$jfMessages.subscriptions_request_getaquote_success,
      });
      this.$jfWebeyez.send({
        goal_key: "upgrade",
        isSuccess: true,
        customData: [{ key: "accountNumber", value: this.quickPurchaseData.serverName }],
      });
      this.toPlans();
    } catch (e) {
      this.$log.error(e);
      this.notifyError(this.$jfMessages.subscriptions_request_getaquote_error);
    } finally {
      this.isLoading = false;
    }
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
    this.$jfWebeyez.send({
      goal_key: "upgrade",
      isSuccess: false,
      errorMessage: errorMessage,
      customData: [{ key: "accountNumber", value: this.quickPurchaseData.serverName }],
    });
  }

  mounted() {
    this.quickPurchaseBus.$emit("shouldDisplayHostedPlanCta", false);
  }

  created() {
    if (!this.planName) {
      this.toPlans();
    }
  }
}
